import React, { useMemo } from "react";
import { styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { getSplitRanksByPlayerSeason } from "@/game-apex/utils/get-split-ranks-by-player-season.mjs";
import ApexUnranked from "@/inline-assets/apex-unranked.svg";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const IconContainer = styled("div")`
  display: flex;
`;

const SeasonFilter = ({ onChange, selected, disabled, hideNoStats }) => {
  const {
    parameters: [profileId],
    searchParams,
  } = useRoute();
  const state = useSnapshot(readState);
  const playerStats = state.apex?.playerStats?.[profileId];
  const modeParam = searchParams.get("mode") || "ALL";
  const seasons = state.apex.meta?.seasons;
  const seasonsFilters = useMemo(() => {
    return Object.values(seasons || {})
      .sort((a, b) =>
        !b.apexSeasonNumber
          ? 1
          : !a.apexSeasonNumber
            ? -1
            : b.apexSeasonNumber - a.apexSeasonNumber,
      )
      .map((s) => {
        let icon;
        if (playerStats && s.apexId) {
          const playerSeason = playerStats?.[s.apexId];
          const playerSplitRanks = getSplitRanksByPlayerSeason(
            playerSeason,
            modeParam,
            s.apexId,
            s,
          );
          const FirstSplitIcon =
            playerSplitRanks?.firstSplit?.icon || ApexUnranked;
          const SecondSplitIcon =
            playerSplitRanks?.secondSplit?.icon || ApexUnranked;

          icon = (
            <IconContainer>
              <FirstSplitIcon style={{ marginRight: "var(--sp-2)" }} />
              <SecondSplitIcon />
            </IconContainer>
          );
        }

        return {
          text:
            s.apexId === "ALL"
              ? ["common:lifetime", "Lifetime"]
              : [
                  "apex:season",
                  "Season {{number}}",
                  {
                    number: s.apexSeasonNumber,
                  },
                ],
          value: s.apexId === "ALL" ? "ALL" : s.apexId,
          icon,
        };
      });
  }, [modeParam, playerStats, seasons]);
  return (
    <Select
      onChange={onChange}
      selected={selected}
      disabled={disabled}
      options={hideNoStats ? seasonsFilters.slice(0, 1) : seasonsFilters}
      hideSelectedIcon
    />
  );
};

export default SeasonFilter;
