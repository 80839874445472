import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import ProfileWeapons from "@/game-apex/components/ProfileWeapons.jsx";
import FilterModes from "@/game-apex/FilterModes.jsx";
import FilterSeasons from "@/game-apex/FilterSeasons.jsx";
import LegendsTable from "@/game-apex/LegendsTable.jsx";
import ProfileOverview from "@/game-apex/ProfileOverview.jsx";
import staticMediaURLs from "@/game-apex/static.mjs";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile, { UnknownPlayerHeader } from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import ProfileEmpty from "@/shared/ProfileEmpty.jsx";
import { useRoute, useTransientRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Profile(props) {
  const { t } = useTranslation();
  const {
    searchParams,
    parameters: [profileIdParam, tab],
    currentPath,
  } = useRoute();
  const {
    state: { transient },
  } = useTransientRoute();

  const profileId = profileIdParam ?? props.profileId;

  const seasonParam = searchParams.get("season") || "ALL";
  const modeParam = searchParams.get("mode") || "ALL";

  const state = useSnapshot(readState);
  const profile = state.apex?.profiles?.[profileId];
  const profileError = profile instanceof Error ? profile : !profileId;
  const iconURL = staticMediaURLs.getLegendImage(
    profile?.hoveredChampionApexId,
  );

  const { xpProgress, xpTotal, lvl } = useMemo(() => {
    if (!profile) return {};
    let lvl = 1;
    let xpProgress = profile.experiencePoints;
    let xpToNextLvl = 100;
    while (xpProgress - xpToNextLvl > 0) {
      xpProgress -= xpToNextLvl;
      lvl++;
      if (xpToNextLvl === 100) {
        xpToNextLvl += 2550;
      } else if (xpToNextLvl === 2650) {
        xpToNextLvl += 1250;
      } else if (xpToNextLvl < 5600) {
        xpToNextLvl += 850;
      } else if (xpToNextLvl < 7850) {
        xpToNextLvl += 750;
      } else if (xpToNextLvl < 8150) {
        xpToNextLvl += 300;
      } else if (xpToNextLvl < 12200) {
        xpToNextLvl += 450;
      } else if (xpToNextLvl < 17900) {
        xpToNextLvl += 150;
      } else if (xpToNextLvl < 18000) {
        xpToNextLvl += 100;
      } else if (xpToNextLvl >= 18000) {
        lvl += Math.floor(xpProgress / 18000);
        xpProgress = xpProgress % 18000;
        break;
      }
    }

    return { xpProgress, xpTotal: xpToNextLvl, lvl };
  }, [profile]);

  const baseUrl = profileId ? `/apex/profile/${profileId}` : "/apex";

  const tabs = useMemo(() => {
    return [
      {
        name: t("common:navigation.overview", "Overview"),
        id: "overview",
        url: baseUrl,
        isNew: false,
      },
      {
        name: t("common:navigation.legends", "Legends"),
        id: "legends",
        url: `${baseUrl}/legends`,
        isNew: false,
      },
      {
        name: t("common:navigation.weapons", "Weapons"),
        id: "weapons",
        url: `${baseUrl}/weapons`,
        isNew: false,
      },
    ];
  }, [baseUrl, t]);

  let InnerComponent;
  switch (tab) {
    case "legends":
      InnerComponent = (
        <Container>
          <Card>
            <LegendsTable />
          </Card>
        </Container>
      );
      break;
    case "weapons":
      InnerComponent = (
        <Container>
          <ProfileWeapons />
        </Container>
      );
      break;
    default: {
      InnerComponent = profileId ? (
        <ProfileOverview profileId={profileId} />
      ) : (
        <ProfileEmpty />
      );
    }
  }

  const links = tabs.map((tab) => ({
    url: tab.url,
    text: tab.name,
  }));

  const updateSeason = (season) => {
    const search = `season=${season}&mode=${modeParam}`;
    updateRoute(currentPath, search, {
      transient,
      page: undefined,
      isUpdate: false,
    });
  };

  const updateMode = (mode) => {
    const search = `season=${seasonParam}&mode=${mode}`;
    updateRoute(currentPath, search, {
      transient,
      page: undefined,
      isUpdate: false,
    });
  };

  return (
    <ProfileLayout>
      {!profileError ? (
        <PageHeader
          imageRound
          image={iconURL}
          title={profile?.username}
          accentText={lvl}
          links={links}
          $xpProgress={xpProgress}
          $xpTotal={xpTotal}
        />
      ) : (
        <UnknownPlayerHeader />
      )}
      <Container>
        <div className="flex gap-sp-2">
          <FilterModes selected={modeParam} onChange={updateMode} />
          {!tab && (
            <FilterSeasons selected={seasonParam} onChange={updateSeason} />
          )}
        </div>
      </Container>
      <Container>
        <SharedProfile>{InnerComponent}</SharedProfile>
      </Container>
    </ProfileLayout>
  );
}

export function meta([profileId]) {
  const profile = readState.apex.profiles[profileId];

  return {
    title: [
      `apex:meta.profile.title`,
      `{{userName}}'s Apex Performance Overview`,
      { userName: profile?.username },
    ],
    description: [
      `apex:meta.profile.description`,
      `View {{userName}}'s Apex profile and see how they perform.`,
      { userName: profile?.username },
    ],
  };
}

export default Profile;
