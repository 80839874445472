import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import staticMediaURLs from "@/game-apex/static.mjs";
import useApexLegends from "@/game-apex/useApexLegends.jsx";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ApexLegendStat = ({ season, mode, profileId }) => {
  const { t } = useTranslation();
  const { legends: myLegends } = useApexLegends({
    season,
    mode,
    profileId,
  });
  const state = useSnapshot(readState);
  const legends = state.apex.meta?.legends;

  if (!myLegends?.length) return null;

  return (
    <ListItemsTruncated
      title={t("common:legends", "Legends")}
      list={myLegends}
      itemLeftContent={(item) => {
        const { champion_id, games_played: matches } = item;
        const legendInfo = legends?.[champion_id];
        const legendImgUrl = staticMediaURLs.getLegendImage(champion_id);
        const legendName = legendInfo?.name || "-";

        return (
          <>
            <img
              className="legend-icon"
              src={legendImgUrl}
              width="40"
              height="40"
              alt={legendName}
            />
            <div>
              <div className="type-callout--bold name">
                <span>{legendName}</span>
              </div>
              <div className="type-caption">
                <span className="sub-stat shade1">
                  {t("common:stats.matchesCount", "{{count, number}} Matches", {
                    count: matches,
                  })}
                </span>
              </div>
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const { kills, placements_win: wins } = item;
        return (
          <>
            <div className="type-caption--bold">
              <span>
                {t("common:stats.winsCount", "{{count, number}} Wins", {
                  count: wins,
                })}
              </span>
            </div>
            <div className="type-caption sub-stat shade2">
              <span>
                {t("common:stats.killsCount", "{{count, number}} Kills", {
                  count: kills,
                })}
              </span>
            </div>
          </>
        );
      }}
    />
  );
};

export default React.memo(ApexLegendStat);
