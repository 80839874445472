import React from "react";
import { Select } from "clutch/src/Select/Select.jsx";

import { GAME_MODES } from "@/game-apex/constants.mjs";

const ModeFilter = ({
  onChange,
  selected,
  disabled,
  hideNoStats,
  hideNoWepStats,
}) => {
  return (
    <Select
      onChange={onChange}
      selected={selected}
      disabled={disabled}
      options={Object.keys(GAME_MODES)
        .filter(
          (m) =>
            !GAME_MODES[m].hidden &&
            (!hideNoStats || !GAME_MODES[m].noStats) &&
            (!hideNoWepStats || !GAME_MODES[m].noWepStats),
        )
        .map((mode) => {
          const modeObj = GAME_MODES[mode];
          return {
            value: mode,
            text:
              (hideNoStats || hideNoWepStats) && modeObj.tBr
                ? [modeObj.tBr, modeObj.labelBr]
                : [modeObj.t, modeObj.label],
          };
        })}
    />
  );
};

export default ModeFilter;
