import type { ApexMatch } from "@/data-models/apex-match.mjs";

type NumberProps<T> = {
  [Property in keyof T as T[Property] extends number
    ? Property
    : never]: Array<number>;
};
type Histogram = NumberProps<ApexMatch["playerMatchStats"][0]> & {
  accuracy?: Array<number>;
  accuracyHs?: Array<number>;
};

/*
 * Note to anyone looking to copy this histogram format,
 * We have these in games like EFT, Fortnite and Apex and they're all slightly different but the majority of the code is the same
 */
export default function getHistogram(
  matches: {
    [gameId: string]: ApexMatch;
  },
  profileId: string,
): Histogram {
  const result = Object.create(null);
  for (const gameId in matches) {
    const match: ApexMatch = matches[gameId];
    if (!match || match instanceof Error) continue;
    if (!Array.isArray(match?.playerMatchStats)) continue;
    const player = match.playerMatchStats.find(
      (i) => i.platformId === profileId,
    );
    if (!player) continue;
    for (const key in player) {
      const value = player[key];
      if (typeof value !== "number") continue;
      if (Array.isArray(result[key])) {
        (result[key] as Array<number>).push(player[key]);
      } else {
        result[key] = [player[key]];
      }
    }
  }
  return result;
}
