import type { ArenaDivision } from "@/game-apex/constants/constants-divisions.mjs";
import { getRankedModeByGameMode } from "@/game-apex/utils.mjs";
import { getDivision } from "@/game-apex/utils/get-division.mjs";

interface PlayerSeasonRankSplit extends Partial<ArenaDivision> {
  rankedPoint?: number;
  rankedRating?: number;
}

export const getSplitRanksByPlayerSeason = (
  seasonData,
  gameMode,
  season,
  seasonMeta,
): {
  firstSplit?: PlayerSeasonRankSplit;
  secondSplit?: PlayerSeasonRankSplit;
  apexSeasonNumber?: number;
} => {
  const rankedMode = getRankedModeByGameMode(gameMode);

  if (!rankedMode || !seasonData?.[rankedMode]?.games_played) {
    return {};
  }

  const ranks: {
    firstSplit?: PlayerSeasonRankSplit;
    secondSplit?: PlayerSeasonRankSplit;
    apexSeasonNumber?: number;
  } = { apexSeasonNumber: seasonMeta?.apexSeasonNumber };

  const hasSplitOccured = seasonMeta?.hasSplitResetOccured;

  // Add rank information for 1st split
  const firstRankscore = hasSplitOccured
    ? seasonData?.[rankedMode]?.firstsplitrankedscore
    : seasonData?.[rankedMode]?.current_rank_score;

  const firstRankInfo = getDivision(Number(firstRankscore), rankedMode, season);

  ranks.firstSplit = {
    ...firstRankInfo,
    rankedPoint: firstRankscore,
    rankedRating: firstRankInfo?.rankRating,
  };

  // Add rank information for 2nd split
  if (hasSplitOccured) {
    const secondRankscore = seasonData?.[rankedMode]?.current_rank_score;
    const secondRankInfo = getDivision(
      Number(secondRankscore),
      rankedMode,
      season,
    );

    ranks.secondSplit = {
      ...secondRankInfo,
      rankedPoint: secondRankscore,
      rankedRating: secondRankInfo?.rankRating,
    };
  }

  return ranks;
};
