import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { kdaColorStyle } from "@/app/util.mjs";
import staticMediaURLs from "@/game-apex/static.mjs";
import { getPlayerStatsByMatch } from "@/game-apex/utils.mjs";
import SharedMatchHistoryHeader from "@/shared/MatchHistoryHeader.jsx";
import { calcRate } from "@/util/helpers.mjs";
import { formatOrdinalNumber, getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function MatchListHeader({ selectedAccountId, matchList }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  const matches = state.apex.matches;

  const { statLines, topLegends } = useMemo(() => {
    const stats = {
      wins: 0,
      matches: 0,
      kills: 0,
      assists: 0,
      damage: 0,
      rp: 0,
    };
    const hash = {};
    const legendStats = {};
    let placementSum = 0;
    for (const matchObj of matchList) {
      const match = matches[matchObj.apexId];
      const playerMatchStats = getPlayerStatsByMatch(match, selectedAccountId);
      if (!playerMatchStats) continue;
      const {
        champion: { apexId } = {},
        survivalTime,
        kills: playerKills,
        assists: playerAssists,
        damage_done,
        team: { placement } = {},
        rankedPoints,
      } = playerMatchStats;

      if (apexId) {
        hash[apexId] = hash[apexId] || {
          id: apexId,
          kills: 0,
          minutes: 0,
        };

        hash[apexId].minutes += survivalTime / 60;
        hash[apexId].kills += playerKills;
      }

      // Get matches
      stats.matches++;

      // Get kills
      if (playerKills) stats.kills += playerKills;

      // Get assists
      if (playerAssists) stats.assists += playerAssists;

      // Get damage
      if (damage_done) stats.damage += damage_done;

      // Get wins
      if (placement === 1) stats.wins++;
      placementSum += placement;

      if (rankedPoints) stats.rp += rankedPoints;

      if (stats.matches >= 20) break;
    }

    const sortedKeys = Object.keys(hash).sort((a, b) => {
      return hash[b].kills - hash[a].kills;
    });

    for (const symbol of sortedKeys) legendStats[symbol] = hash[symbol];

    const kd = stats.kills / stats.matches || 0;
    const ad = stats.assists / stats.matches || 0;
    const dmgPerMatch = stats.damage / stats.matches || 0;
    const avgPlacement = calcRate(placementSum, stats.matches, 0);

    const statLines = [
      {
        stat: formatOrdinalNumber(getLocale(), avgPlacement, 0),
        description: t("apex:stats.avgPlacement", "Avg. Placement"),
        statColor: stats.matches > 0 ? "var(--turq)" : "var(--shade3)",
      },
      {
        stat: kd.toLocaleString(language),
        description: t("apex:stats.killsPerMatch", "K/Match"),
        statColor: stats.matches > 0 ? kdaColorStyle(kd) : "var(--shade3)",
      },
      {
        stat: ad.toLocaleString(language),
        description: t("apex:stats.assistsPerMatch", "A/Match"),
        statColor: stats.matches > 0 ? kdaColorStyle(ad) : "var(--shade3)",
      },
      {
        stat: dmgPerMatch.toLocaleString(language, {
          maximumFractionDigits: 1,
        }),
        description: t("apex:stats.dmgPerMatch", "Dmg/Match"),
        statColor:
          stats.matches > 0
            ? kdaColorStyle(dmgPerMatch / 100)
            : "var(--shade3)",
      },
      {
        stat: stats.rp?.toLocaleString(language),
        description: t("apex:stats.lp", "LP"),
        statColor:
          stats.rp === 0
            ? "var(--shade3)"
            : stats.rp > 0
              ? "var(--turq)"
              : "var(--red)",
      },
    ];

    const topLegends = Object.keys(legendStats)
      .concat(Array(3).fill())
      .slice(0, 3)
      .map((key) => {
        const stat = legendStats[key];
        if (!stat) return null;
        const { id } = stat;
        const iconUrl = staticMediaURLs.getLegendImage(id);
        const keyString = key?.toString();
        const statColor =
          typeof stat.kills === "number" ? "inherit" : "var(--shade3)";

        return {
          key: keyString,
          iconUrl: iconUrl,
          statColor: statColor,
          stat:
            typeof stat.kills === "number"
              ? t("common:stats.countKills", "{{count, number}} Kills", {
                  count: stat.kills,
                })
              : "-",
        };
      });

    return {
      statLines,
      topLegends,
    };
  }, [selectedAccountId, matchList, matches, t, language]);

  return (
    <SharedMatchHistoryHeader
      padding={"var(--sp-2) var(--sp-5)"}
      statColumns={statLines}
      IconList={topLegends}
    />
  );
}

export default memo(MatchListHeader);
